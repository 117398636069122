var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "화학자재 > 구성물질 > 규제항목 정량값",
            gridHeight: _vm.gridHeight,
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            merge: _vm.grid.merge,
            columnSetting: false,
            isFullScreen: false,
            isExcelDown: false,
            filtering: false,
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "quantity"
                    ? [
                        _c("c-number-column", {
                          attrs: {
                            editable: _vm.editable,
                            col: col,
                            props: props,
                          },
                          on: {
                            datachange: function ($event) {
                              return _vm.datachange(props)
                            },
                          },
                          model: {
                            value: props.row[col.name],
                            callback: function ($$v) {
                              _vm.$set(props.row, col.name, $$v)
                            },
                            expression: "props.row[col.name]",
                          },
                        }),
                      ]
                    : [
                        props.row.appliStandQuantity &&
                        props.row.appliStandQuantity.length > 0
                          ? _c("c-select", {
                              attrs: {
                                stype: "tableselect",
                                editable: _vm.editable,
                                comboItems: _vm.comboItems(props.row),
                                type: "none",
                                itemText: "codeName",
                                itemValue: "code",
                              },
                              on: {
                                datachange: (val) =>
                                  _vm.changeSelect(val, props.row),
                              },
                            })
                          : [_vm._v(" 참고할 사항 없음 ")],
                      ],
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable,
                        expression: "editable",
                      },
                    ],
                    attrs: {
                      isSubmit: _vm.isSave,
                      url: _vm.saveUrl,
                      param: _vm.grid.data,
                      mappingType: "PUT",
                      label: "LBLSAVE",
                      icon: "save",
                    },
                    on: {
                      beforeAction: _vm.saveQuantity,
                      btnCallback: _vm.saveQuantityCallback,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }